<template>
	<div id="pageChangePassword" class="flex w-full layout--middle">
		<div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card>
				<div class="h-full sm:w-full md:w-1/2 lg:w-1/2 mx-auto self-center bg-white bg-dark pt-10 pb-10">
					<template v-if="msg === 0">
						<div class="vx-card__title mb-8">
							<h4 class="mb-4">{{ $t("Changement de mot de passe") }}</h4>
							<p>{{ $t("Veuillez entrer votre nouveau mot de passe.") }}</p>
						</div>

						<div class="center grid">
							<!-- password -->
							<vs-row>
								<vs-col
									vs-type="flex"
									vs-justify="start"
									vs-align="center"
									class="p-0"
									style="width: 100%"
								>
									<div class="relative w-full">
										<vs-input
											ref="inputPwd"
											v-model="inputPwd"
											v-validate="'required|min:8'"
											name="inputPwd"
											:type="password_type"
											:label-placeholder="$t('Nouveau mot de passe')"
											class="w-full"
											icon="icon icon-lock"
											icon-pack="feather"
											color="var(--black)"
										/>

										<vs-icon
											:icon="password_type == 'password' ? 'icon-eye' : 'icon-eye-off'"
											icon-pack="feather"
											size="1.1rem"
											class="mt-4 icon_eye changepassword_eye cursor-pointer"
											@click="togglePassword"
										/>
									</div>
								</vs-col>
								<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
									<span v-show="errors.has('inputPwd')" class="text-danger text-sm">{{
										errors.first("inputPwd")
									}}</span>
								</vs-col>
							</vs-row>

							<!-- confirm password -->
							<vs-row>
								<vs-col
									vs-type="flex"
									vs-justify="start"
									vs-align="center"
									class="p-0"
									style="width: 100%"
								>
									<div class="relative w-full">
										<vs-input
											v-model="inputPwd2"
											v-validate="'required|confirmed:inputPwd'"
											name="inputPwd2"
											:type="password_type"
											data-vv-as="inputPwd"
											:label-placeholder="$t('Confirmer votre nouveau mot de passe')"
											class="w-full mt-6"
											icon="icon icon-lock"
											icon-pack="feather"
											color="var(--black)"
											@keypress.enter="changePwd"
										>
											{{ $t("Confirmer mot de passe") }} :
										</vs-input>

										<vs-icon
											:icon="password_type == 'password' ? 'icon-eye' : 'icon-eye-off'"
											icon-pack="feather"
											size="1.1rem"
											class="mt-4 icon_eye changepassword_eye cursor-pointer"
											@click="togglePassword"
										/>
									</div>
								</vs-col>
								<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
									<span v-show="errors.has('inputPwd2')" class="text-danger text-sm">{{
										errors.first("inputPwd2")
									}}</span>
								</vs-col>
							</vs-row>
						</div>

						<!-- soumission du form -->
						<div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8">
							<div class="vx-col w-full justify-center">
								<s-button
									ref="submit"
									class="w-full vs-con-loading__container mb-3"
									:disabled="disabled"
									@click="changePwd"
								>
									{{ $t("Valider") }}
								</s-button>

								<s-button class="w-full mt-3" :to="{ name: 'Login' }" type="border">
									{{ $t("Retour à la connexion") }}
								</s-button>
							</div>
						</div>
					</template>

					<!-- request OK -->
					<template v-if="msg === 200" class="p-8">
						<p class="mb-5">
							{{ $t("Votre mot de passe a bien été modifié, vous pouvez maintenant vous connecter.") }}
						</p>

						<s-button class="w-full" :to="{ name: 'Login' }"> {{ $t("Retour à la connexion") }} </s-button>
					</template>

					<!-- request error (tokken mort ou invalide) -->
					<template v-if="msg === 422" class="vs-col">
						<p>{{ $t("Le lien que vous avez utilisé est incorrect ou périmé.") }}</p>

						<s-button class="w-full my-6" :to="{ name: 'Sendpassword' }">
							{{ $t("Redemander un lien") }}
						</s-button>

						<s-button class="w-full my-6" :to="{ name: 'Login' }" type="border">
							{{ $t("Retour à la connexion") }}
						</s-button>
					</template>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	import { Validator } from "vee-validate";
	import i18n from "../i18n";

	const dict = {
		custom: {
			inputPwd: {
				required: () => i18n.t("Veuillez entrer votre mot de passe"),
				min: () => i18n.t("Veuillez entrer 8 caractères minimum")
			},
			inputPwd2: {
				required: () => i18n.t("Veuillez confirmer votre mot de passe"),
				confirmed: () => i18n.t("Les deux mots de passe ne sont pas identiques")
			}
		}
	};

	// appel du dictionnaire pour la validation formulaire
	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				tokenURL: this.$route.params.tokenURL,
				inputPwd: "",
				inputPwd2: "",
				boolValide: false,
				msg: 0,
				disabled: false,
				password_type: "password"
			};
		},
		methods: {
			togglePassword() {
				this.password_type = this.password_type === "password" ? "text" : "password";
			},
			async changePwd() {
				const isFormValid = await this.$validator.validateAll();

				if (isFormValid) {
					this.disabled = true;
					this.$vs.loading({
						type: "default",
						color: "var(--white)",
						background: "var(--primary)",
						container: this.$refs.submit.$el,
						scale: 0.5
					});

					var payload = {
						password: this.inputPwd,
						forgotten_password_token: this.tokenURL
					};

					this.$store
						.dispatch("changePassword", payload)
						.then(() => {
							this.disabled = false;
							this.msg = 200;
							this.$vs.loading.close(this.$refs.submit.$el);
						})
						.catch(err => {
							this.disabled = false;
							this.msg = err?.response?.status;
							this.$vs.loading.close(this.$refs.submit.$el);
						});
				}
			}
		}
	};
</script>

<style>
	.pb-35 {
		padding-bottom: 35px;
	}

	.changepassword_eye {
		margin-top: 11px !important;
	}
</style>
